













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep .item-left {
  .el-input__inner {
    color: #ffffff !important;
    background-color: #0c0e3f !important;
    border: 1px solid transparent;
    &::placeholder {
      color: #ffffff;
      opacity: 0.2;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .el-input__inner:focus {
    border: 1px solid #4b45ff;
  }

  .el-icon-arrow-up:before {
    content: "\e790";
    color: #fff;
    opacity: 0.5;
    width: 12px;
    height: 7px;
  }
  .el-select-dropdown {
    height: auto;
    border: 1px solid transparent;
    background-color: #0c0e3f;
  }
  .el-scrollbar {
    height: auto;
  }
  .el-scrollbar__wrap {
    margin-bottom: 0 !important;
  }
  .el-select-dropdown__item {
    height: 36px;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  .el-select-dropdown__item:hover {
    background-color: #262963;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #262963;
  }
  .el-select-dropdown__item.selected {
    background-color: #262963;
  }
}

.audience-source-sex {
  position: relative;
  height: 100px;
  ::v-deep .el-progress--circle .el-progress__text,
  .el-progress--dashboard .el-progress__text {
    color: white;
  }

  .man-box {
    position: absolute;
    left: 4%;
    top: 50px;
    .iconfont {
      position: absolute;
      right: 25%;
      top: 35%;
    }
    ::v-deep .el-progress--circle .el-progress__text,
    .el-progress--dashboard .el-progress__text {
      position: absolute;
      left: 60%;
      top: 90%;
      color: white;
    }
  }
  .el-progress {
    ::v-deep path:first-child {
      stroke: rgba(255, 255, 255, 0.1);
    }
  }

  .female-box {
    position: absolute;
    left: 45%;
    top: 50px;
    .iconfont {
      position: absolute;
      left: 63%;
      top: 35%;
    }
    ::v-deep .el-progress--circle .el-progress__text,
    .el-progress--dashboard .el-progress__text {
      position: absolute;
      left: 60%;
      top: 10%;
      color: white;
    }
  }
  // .line {
  //   border-bottom: 1px dashed #3f52a2;
  //   width: 500px;
  //   position: absolute;
  //   left: 41.5%;
  //   margin-left: -200px;
  //   top: 110px;
  // }
}
.exam-content {
  height: 100%;
  width: 100%;
  background: #070932;

  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;

    .el-scrollbar__view {
      height: 100%;
    }
  }

  .main-content {
    width: 500px;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.live-data {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  // overflow: auto;
  .operate {
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    .title-right {
      -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
      -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
      -o-transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    img {
      vertical-align: middle;
    }
  }
  .title-item {
    display: grid;
    grid-template-columns: repeat(5, 18.95%);
    grid-template-rows: 145px;
    grid-gap: 1.3%;
    width: 100%;
    height: auto;
    color: #fff;
    .title-one {
      background: url("../../assets/image/title-one.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-two {
      background: url("../../assets/image/title-two.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-three {
      background: url("../../assets/image/title-three.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-four {
      background: url("../../assets/image/title-four.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    .title-five {
      background: url("../../assets/image/title-five.png") no-repeat;
      height: 100%;
      background-size: cover;
      border-radius: 15px 15px 0px 15px;
      display: table;
      width: 100%;
      .cont {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 35px;
        .title-num {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
  }
  .title-item-two {
    display: grid;
    grid-template-columns: repeat(2, 49.3%);
    grid-template-rows: 378px 378px;
    grid-gap: 1.3%;
    width: 100%;
    height: auto;
    color: #fff;
    grid-row-gap: 3%;
    margin-top: 20px;
    .item-left {
      background: #0c0e3f;
    }
    .item-right {
      background-color: #0c0e3f;
    }
  }
  .title-item-foot {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    grid-template-rows: 690px;
    grid-gap: 1.3%;
    width: 100%;
    height: auto;
    color: #fff;
    grid-row-gap: 3%;
    margin-top: 45px;

    .item-foot-right {
      background: #0c0e3f;
    }
  }
}
.paging-box {
  width: 220px;
  height: 10px;
  background: #0c0e3f;
  margin-right: 98px;
  float: right;
}
::v-deep .el-pagination {
  .btn-prev {
    color: #999;
    background: #0c0e3f;
  }
  .btn-next {
    color: #999;
    background: #0c0e3f;
  }
}

::v-deep .pages-center .el-pager .number {
  color: #fff;
  background: #0c0e3f;
}
::v-deep .pages-center .el-pager .active {
  background: #2821fc;
  color: #fff;
}
::v-deep .pages-center .el-pager .btn-quicknext {
  color: #fff;
  background: #0c0e3f;
}
::v-deep .pages-center .el-pager .btn-quickprev {
  color: #fff;
  background: #0c0e3f;
}
.ranking{position: absolute;left: 58%;top: 50px;}
@media screen and (min-width:900px) and (max-width:1300px){
   #s-map .ranking{position: absolute;left: 48%;}
}
@media screen and (min-width:1300px) and (max-width:1400px){
   #s-map .ranking{position: absolute;left: 52%;}
}
@media screen and (min-width:1400px) and (max-width:1700px){
   #s-map .ranking{position: absolute;left: 55%;}
}
@media screen and (min-width:1700px) and (max-width:1900px){
   #s-map .ranking{position: absolute;left: 56%;}
}

